<template>
  <el-container>
    <Header pageName="官网案例"></Header>
    <el-main>
      <el-button class="el-icon-plus" size="small" type="primary" @click="showAdd">添加案例</el-button>
      <el-form class="el-form-search" label-width="90px">
        <el-form-item label="案例名称：">
          <el-input size="small" v-model="name" placeholder="请输入案例名称"></el-input>
        </el-form-item>
        <el-form-item label=" " label-width="20px">
          <el-button size="small" @click="search" type="primary">搜索</el-button>
          <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="name" label="案例名称" align="center"></el-table-column>
        <el-table-column prop="website_desc" label="案例简介" align="center"></el-table-column>
        <el-table-column prop="image" label="案例背景" align="center">
          <template v-slot="{ row }">
            <el-image style="width: 70px; height: 70px" :src="row.image"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="logo" label="案例logo" align="center">
          <template v-slot="{ row }">
            <el-image style="width: 50px; height: 50px" :src="row.logo"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="qrcode" label="案例二维码" align="center">
          <template v-slot="{ row }">
            <el-image style="width: 50px; height: 50px" :src="row.qrcode"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="is_show" label="是否显示" align="center">
          <template v-slot="{ row }">
            <el-switch v-model="row.is_show" :active-value="1" :inactive-value="0" @change="changeShow"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240" align="center" fixed="right">
          <template v-slot="{ row, $index }">
            <el-button @click="edit(row)" type="text" size="small">编辑</el-button>
            <el-button @click="del(row, $index)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
    <el-dialog title="添加/编辑案例" :visible.sync="is_showAdd" width="650px">
      <el-form ref="form" :model="addForm" :rules="rules" label-width="110px">
        <el-form-item label="案例名称：" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="案例简介：" prop="website_desc">
          <el-input v-model="addForm.website_desc" type="textarea" :rows="4"></el-input>
        </el-form-item>
        <el-form-item label="是否显示：" prop="name">
          <el-switch v-model="addForm.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="案例logo：" prop="logo">
          <add-img :path="addForm.logo" :url="1" @getImg="val => (addForm.logo = val)"></add-img>
        </el-form-item>
        <el-form-item label="案例二维码：" prop="qrcode">
          <add-img :path="addForm.qrcode" :url="1" @getImg="val => (addForm.qrcode = val)"></add-img>
        </el-form-item>
        <el-form-item label="案例背景：" prop="image">
          <add-img :path="addForm.image" :url="1" @getImg="val => (addForm.image = val)"></add-img>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="is_showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import Header from './components/header';
import addImg from '@/components/addImg';
import Paging from '@/components/paging';
export default {
  components: {
    Header,
    addImg,
    Paging
  },
  data() {
    return {
      name: '',
      page: 1,
      rows: 10,
      list: [],
      total_number: 0,
      addForm: {
        name: '',
        logo: '',
        website_desc: '',
        image: '',
        qrcode: '',
        is_show: 1,
      },
      is_showAdd: !1,
      rules: {
        name: [
          {
            required: true,
            message: '请输入案例名称',
            trigger: 'blur',
          },
        ],
        logo: [
          {
            required: true,
            message: '请添加案例logo',
            trigger: 'blur',
          },
        ],
        website_desc: [
          {
            required: true,
            message: '请输入案例简介',
            trigger: 'blur',
          },
        ],
        image: [
          {
            required: true,
            message: '请添加案例背景',
            trigger: 'blur',
          },
        ],
        qrcode: [
          {
            required: true,
            message: '请添加案例二维码',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    searchClear() {
      this.page = 1;
      this.rows = 10;
      this.name = '';
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    getList() {
      this.$axios
        .post(this.$api.admin.caseList, {
          page: this.page,
          rows: this.rows,
          name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showAdd() {
      this.addForm = {
        name: '',
        logo: '',
        website_desc: '',
        image: '',
        qrcode: '',
        is_show: 1,
      };
      this.is_showAdd = !0;
    },
    add() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let addForm = Object.assign({}, this.addForm);
          let url = addForm.id ? this.$api.admin.caseEdit : this.$api.admin.caseAdd;
          if (addForm.id) delete addForm.create_time;
          this.$axios.post(url, addForm).then(res => {
            if (res.code == 0) {
              this.is_showAdd = !1;
              this.getList();
              this.$message.success(addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    edit(row) {
      this.addForm = Object.assign({}, row);
      this.is_showAdd = !0;
    },
    del(row, i) {
      this.$axios
        .post(this.$api.admin.caseDel, {
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.list.splice(i, 1);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeShow() {},
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      margin-top: 10px;
    }
  }
}
</style>
